import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"

const Frame = styled.div`
  padding: 30px 10%;

  h1 {
    text-align: center;
    margin-bottom: 65px;
  }

  tr,
  td,
  tbody,
  table,
  a {
    width: 100%;
  }

  td {
    border: solid rgba(164, 164, 164, 0.27) 1px;

    a {
      padding: 5px;
      display: block;
      text-decoration: underline;
      text-decoration-color: #bbbbbb;
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
`

const Page = () => {
  // const data = useStaticQuery(graphql`
  //     {
  //         allMenuNodesForSeo {
  //             edges {
  //                 node {
  //                     data {
  //                         data {
  //                             menu {
  //                                 products {
  //                                     id
  //                                     name
  //                                 }
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         }
  //     }
  // `)
  //  const products = data?.allMenuNodesForSeo?.edges[0]?.node?.data?.data?.menu?.products;
  return (
    <Frame>
      <h1> All products </h1>
      <table>
        {/*<tbody>*/}
        {/*{products.map((item , i )=>{*/}
        {/*  return   <tr key={i}>*/}
        {/*    <td>*/}
        {/*      <Link to={'/products/'+item.id} > {item.name}  </Link>*/}
        {/*    </td>*/}
        {/*  </tr>*/}
        {/*})}*/}
        {/*</tbody>*/}
      </table>
    </Frame>
  )
}

export default Page
